// Set file variable
$filename: 'scss/custom/_widgets.scss';

/*==============================================================================
  @Widget Content - #{$filename}
==============================================================================*/

.widget .button {
  @extend .button.secondary;
}

/*==============================================================================
  @Widget Types - #{$filename}
==============================================================================*/

/**
 * Recent News
 */

.widget-recent-news {
  ul {
    list-style: none;
    margin: 0.5em 0;

    li {
      margin: 0;
      padding: 0.5em 0;
    }

    li + li {
      border-top: 1px solid rgba($black, 0.1);
    }

    .timestamp {
      color: $color-light;
      font-style: italic;
    }
  }
}

/**
 * Testimonials
 */

.widget-testimonials {
  blockquote {
    background: none;
    padding: 0;

    footer {
      font-weight: font-weight('semi-bold');
    }
  }
}

/**
 * Specials
 */

.widget-specials {
  img {
    margin: 0;
    width: 100%;
    height: auto;
  }
}

/**
 * Image Widget
 */

.widget-img {
  padding: 0.5em 1em;

  img {
    margin: 0.5em 0;
  }
}

/**
 * Rating
 */

.widget-rating {
  a:hover {
    text-decoration: none;
  }
}

ol.stars {
  list-style: none;
  margin: 1em 0;

  li {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }

  .icon {
    margin: 0;
    font-size: 2em;
  }

  .icon-star {
    color: $accent;
    color: var(--accent, $accent);
  }

  .icon-star-half {
    opacity: 0.5;
  }

  .icon-star-empty {
    color: $color-light;
  }

  .text {
    color: $color;
    margin: 0 0.5em;
  }
}

/**
 * Reviews Results
 */

.widget-review-results {
  padding: 1em 1.5em;
  font-size: inherit;

  h2 {
    margin: 0 0 0.25rem;
    font-size: px-to-em(22px);
    color: $accent;
  }

  p {
    margin: 0 0.25rem 0 0;
  }
}

/**
 * Featured
 */

.widget-featured {
  ul {
    margin: 0;
    list-style: none;

    li {
      margin: 0;
    }

    li + li {
      border-top: 1px solid rgba($black, 0.1);
    }

    a {
      display: flex;
      align-items: center;
      padding: 1em 0;
    }

    .icon {
      margin-right: 1rem;
      font-size: 2em;
    }

    .text {
      color: $color;
      font-size: px-to-em(16px, 14px);
    }

    a:hover {
      text-decoration: none;

      .text {
        color: $color-link-hover;
      }
    }
  }
}

/**
 * Featured Logos
 */

.widget-featured-logos {
  text-align: center;

  ul {
    list-style: none;
    margin: 0;
    padding: 1em;
    background: $white;
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;

    li {
      display: inline-block;
      margin: 0;
    }
  }

  img {
    margin: 0.5em;
  }
}

/**
 * Hours
 */

.widget-hours {
  ul {
    list-style: none;
    margin: 1em 0;
    text-align: left;

    li {
      margin: 0;
      padding: 0.5em 0;
    }

    li + li {
      border-top: 1px solid rgba($black, 0.1);
    }
  }
}

/**
 * Contact
 */

.widget-contact {
  ul {
    list-style: none;
    margin: 1em 0;
    text-align: left;

    li {
      margin: 0;
      padding: 0.5em 0;
    }

    li + li {
      border-top: 1px solid rgba($black, 0.1);
    }
  }
}

/**
 * Connect
 */

.widget-connect {
  .social-links {
    justify-content: center;
  }
}

/**
 * Call to Action
 */

.widget-cta {
  text-align: center;

  .action {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      display: block;
      width: 100%;
    }

    em {
      margin: 1em 0 0 0;
      color: $color-light;
    }
  }
}

.widget-cta {
  background-color: $widget-background;
}

.widget-cta.alt {
  border: 1px solid rgba($black, 0.1);
  background-color: $white;
}

@include media-min('small') {
  .widget-cta {
    text-align: left;

    .action {
      flex-direction: row;

      .button {
        display: inline-block;
        width: auto;
        flex: 0 0 auto;
      }

      em {
        margin: 0 0 0 1.5em;
      }
    }
  }
}

@include media-min('large') {
  .widget.widget-cta {
    background-image: url('/fs-vision/dist/img/widget-cta-1.png');
    background-repeat: no-repeat;
    background-position: -3em 0;
    background-size: auto 100%;
    padding-left: 12em;
  }

  .widget.widget-cta.alt {
    background-image: url('/fs-vision/dist/img/widget-cta-2.png');
    background-repeat: no-repeat;
    background-position: -5em 0;
    background-size: auto 100%;
    padding-left: 12em;
  }
}

/**
 * Widget Action
 */

.widget-action {
  p {
    text-align: center;
  }

  .button,
  .text {
    display: block;
    width: 100%;
  }

  .text {
    margin: 1em 0 0 0;
    font-size: px-to-em(20px);

    .phone {
      font-size: 1em;
      white-space: nowrap;
      font-weight: font-weight('semi-bold');
    }
  }
}

@include media-min('large') {
  .widget-action {
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .button,
    .text {
      flex: 1 1 50%;
    }

    .text {
      margin: 0 0 0 1em;
    }
  }
}

/**
 * Locations
 */

.widget-locations {
  padding: 0.5em 0;
  background: none;
  border-top: 1px solid rgba($black, 0.1);
  border-radius: 0;

  h3 {
    margin: 0.5rem 0 1rem;
  }

  .icon-map-marker,
  .icon-telephone,
  .icon-clock {
    float: left;
    margin: -2px 0.5em 0 0;
    font-size: 24px;
  }

  .vcard {
    margin-bottom: 2em;
  }

  .loc-details .adr,
  .loc-details .tel {
    display: block;
    padding: 0 0 0 36px;
  }

  .loc-hours {
    margin: 1em 0 0 0;

    ul {
      margin: 0 0 1em 0;
      list-style: none;
    }

    ul li {
      margin: 0 0 0.5em 0;
    }
  }
}

@include media-min('medium') {
  .widget-locations {
    padding: 0.5em 1em;
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    padding: 1em 2em;
  }
}

// Single Location
.widget-locations.single-loc {
  .loc-hours {
    margin-top: 2em;
  }

  .loc-hours ul {
    padding: 0 0 0 36px;
  }
}

@include media-min(620px) {
  .widget-locations.single-loc {
    .loc-content {
      display: flex;
      justify-content: space-between;
      margin-top: -1em;
    }

    .loc-details,
    .loc-hours {
      flex: 1 1 auto;
    }

    .loc-details {
      margin-right: 2em;
    }

    .loc-hours {
      margin-top: 1em;
    }
  }
}

// Multi Locations
.widget-locations.multi-loc {
  margin: 0 20px 20px 0;

  .loc-hours {
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
  }

  .loc-hours .trigger {
    position: relative;
    display: block;
    margin: 0;
    padding: 0.75em 1em;
    border: none;
    color: $color;

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 100%;
      left: 1em;
      right: 1em;
      height: 1px;
      margin: 0;
      background: rgba($black, 0.1);
    }

    &:hover {
      color: $accent;
      text-decoration: none;
    }
  }

  .loc-hours ul {
    display: none;
    padding: 1em;
    margin: 0;

    li:last-child {
      margin-bottom: 0;
    }
  }

  .loc-hours.active .trigger {
    color: $accent;

    &:after {
      display: block;
    }
  }

  .loc-hours.active ul {
    display: block;
  }
}

@include media-min('medium') {
  .widget-locations.multi-loc .loc-hours {
    margin-left: -1em;
    margin-right: -1em;
  }
}

// Masonry
.masonry-locations {
  margin: 20px -20px 0 0;
}

.masonry-locations .item {
  float: left;
  width: 100%;
  margin: 0;
}

@include media-min(550px) {
  .masonry-locations .item {
    width: 50%;
  }
}

@include media-min('medium') {
  .masonry-locations .item {
    width: 100%;
  }
}

@include media-min(850px) {
  .masonry-locations .item {
    width: 50%;
  }
}
