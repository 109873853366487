// Set file variable
$filename: 'scss/custom/_tpl-shop.scss';

/*==============================================================================
  @Shop - #{$filename}
==============================================================================*/

.shop-options {
  margin: 1em 0 2em;

  .option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 1em 0 0;
    padding: 1em;
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    font-size: px-to-em(18px);
    line-height: 1.3em;
    text-align: center;
    color: $color;
    text-transform: uppercase;
    transition: all 0.2s linear;

    p {
      margin: 0;
    }

    * + p {
      margin: 1em 0 0;
    }
    
    .icon {
      font-size: 50px;
      text-align: center; 
      color: var(--color-link-hover, var(--accent, $color-link-hover));
    }

    .button {
      display: block;
      width: 100%;
      text-transform: none;
      font-size: px-to-em(16px, 18px);
    }
  }
  
  .helper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 1em 0 0;
    padding: 1em;
    // border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    font-size: px-to-em(18px);
    line-height: 1.3em;
    text-align: center;
    color: $color;
    transition: all 0.2s linear;
    background: var( --bg-widget, var(--shade, $bg-widget));

    h5 {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0; 
      padding: 0;
    }

    p {
      font-size: 0.75em;
      margin: 0;
      padding: 0;
    }
    
    * + p {
      margin: 1em 0 0;
    }

    .button {
      display: block;
      width: 100%;
      text-transform: none;
      font-size: px-to-em(16px, 18px);
    }
  }
}

.sticky-helper {
    display: block;
    clear: both;
    margin: 1em 0 0;
    padding: 1em;
    // border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    font-size: px-to-em(18px);
    line-height: 1.3em;
    text-align: center;
    color: $color;
    transition: all 0.2s linear;
    background: var( --bg-widget, var(--shade, $bg-widget));

    h5 {
      font-size: 1.2em;
      font-weight: bold;
      margin: 0; 
      padding: 0;
    }

    p {
      font-size: 0.75em;
      margin: 0;
      padding: 0;
    }
    
    * + p {
      margin: 1em 0 0;
    }

    .button {
      display: block;
      width: 100%;
      text-transform: none;
      font-size: px-to-em(16px, 18px);
    }
  }

@include media-min('medium') {
  .shop-options {
    display: flex;
    align-items: stretch;
    .option {
      flex: 1 1 50%;
      padding: 2em 1em;
      .button {
        margin-bottom: -(px-to-em(18px, 16px));
      }
    }
    .option + .option {
      margin-left: 1em;
    }
    
    .helper {
      flex: 1 1 50%;
      padding: 2em 1em;
      .button {
        margin-bottom: -(px-to-em(18px, 16px));
      }
    }
    .option + .helper {
      margin-left: 1em;
    }
  }
}
